import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyAKxD2tjHZBHI6qf-K47JT3xH3q17BZ-1Q",
  authDomain: "tacco-59003.firebaseapp.com",
  databaseURL: "https://tacco-59003.firebaseio.com",
  projectId: "tacco-59003",
  storageBucket: "tacco-59003.appspot.com",
  messagingSenderId: "717667672109",
  appId: "1:717667672109:web:4d4b1be50043f8561e98a4",
  measurementId: "G-LTJBTC6F7G"
};

firebase.initializeApp(config);
const auth = firebase.auth();
auth.languageCode = 'vi';
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    localStorage._ra___ = user.ra;
  } else {
    // No user is signed in.
  }
});

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
