import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadContracts as loadContractsAction,
  loadContractsSuccess,
  addContractSuccess,
  updateContractSuccess,  
  exportExcelContracts,
  exportExcelContractsSuccess,
  exportExcelContractsFailed
} from '../actions/Contract';

import {
  CONTRACTS_LOAD,
  CONTRACT_ADD,
  CONTRACT_UPDATE,
  CONTRACT_DELETE,
  CONTRACT_DELETE_ALL,
  CONTRACTS_EXPORT_EXCEL,
  CONTRACTS_EXPORT_EXCEL_SUCCESS,
  CONTRACTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getContractById = (id, callback) => {
  Api().get('contract/' + id).then(response => {
    callback(response.data);
  })
}

export const changeContractStatus = (model, callback) => {
  Api().post('contract/change-status', model).then(response => {
    callback(response);
  })
}

export const getAllContracts = (callback) => {
  Api().post('contract/search', { 
    limit: GET_ALL_SIZE,
    orderBy: "id",
    descending: true
  })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxContractCodeSorted = (callback) => {
  Api().post('contract/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted",
    descending: true
  })
    .then(response => {
      let codeSorted = 1;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadContractsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`contract/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`contract`, { params })
    .then(data => data)
    .catch(error => error);
}


const addContractRequest = async (payload) =>
  await Api().post(`contract`, payload)
    .then(data => data)
    .catch(error => error);

const updateContractRequest = async (payload) =>
  await Api().patch(`contract/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteContractRequest = async (id) =>
  await Api().delete(`contract/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadContracts({ payload }) {
  try {
    const data = yield call(loadContractsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadContractsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadContracts() {
  yield takeEvery(CONTRACTS_LOAD, loadContracts);
}

function* loadContract({ selectedContractId }) {
  try {
    const data = yield call(loadContractsRequest, selectedContractId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadContractsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadContract() {
  yield takeEvery(CONTRACTS_LOAD, loadContract);
}


function* addContract({ payload }) {
  try {
    const data = yield call(addContractRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addContractSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddContract() {
  yield takeEvery(CONTRACT_ADD, addContract);
}


function* updateContract({ payload }) {
  try {
    const data = yield call(updateContractRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateContractSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateContract() {
  yield takeEvery(CONTRACT_UPDATE, updateContract);
}

function* deleteContract({ payload }) {
  try {
    const data = yield call(deleteContractRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadContractsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllContracts({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteContractRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadContractsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteContract() {
  yield takeEvery(CONTRACT_DELETE, deleteContract);
}

function* processDeleteAllContracts() {
  yield takeEvery(CONTRACT_DELETE_ALL, deleteAllContracts);
}


const exportContractsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`contract/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}


function* exportContracts({ payload }) {
  try {
    const data = yield call(exportContractsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelContractsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportContracts() {
  yield takeEvery(CONTRACTS_EXPORT_EXCEL, exportContracts);
}

export default function* ContractSagas() {
  yield all([fork(processLoadContracts),
  fork(processLoadContract),
  fork(processAddContract),
  fork(processUpdateContract),
  fork(processDeleteAllContracts),
  fork(processDeleteContract),
  fork(processExportContracts)
]);
}
